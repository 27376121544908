import React from "react";
import "./Catalog.css";
// Cards
import tarkov from "../../images/games__tarkov.svg";
import tarkov_logo from "../../images/games__tarkov_logo.svg";
import valorant from "../../images/games__valorant.svg";
import valorant_logo from "../../images/games__valorant_logo.svg";
import fortnite from "../../images/games__fortnite.svg";
import fortnite_logo from "../../images/games__fortnite_logo.svg";
import rust from "../../images/games__rust.svg";
import rust_logo from "../../images/rust.svg";
import apex from "../../images/games__apex.svg";
import apex_logo from "../../images/games__apex_logo.svg";
import dayz from "../../images/games__dayz.svg";
import dayz_logo from "../../images/dayz.svg";
import scum from "../../images/games__scum.svg";
import scum_logo from "../../images/scum.svg";
import rainbow from "../../images/games__r6s.svg";
import rainbow_logo from "../../images/r6s_white.svg";
import pubg from "../../images/games__pubg.svg";
import pubg_logo from "../../images/pubg_logo.svg";
import bloodhunt from "../../images/games__bloodhunt.svg";
import bloodhunt_logo from "../../images/bloodhunt_logo.svg";
import warface from "../../images/games__warface.svg";
import warface_logo from "../../images/warface_white.svg";
import warzone from "../../images/games__warzone.svg";
import warzone_logo from "../../images/warzone_white.svg";
import wot from "../../images/product/products_wot.jpg";
import wot_logo from "../../images/wot_white.svg";
import GamesCatalogTitle from "./GamesCatalogTitle";
import warthunder from "../../images/wart.jpg";
import warthunder_logo from "../../images/warthunder.png";
import other_logo from "../../images/games__logo_box.svg";
import tgprems from "../../images/tgprem.png";
function GamesCatalog() {
  return (
    <div className="select__games_width">
      <div className="select__games">
      <GamesCatalogTitle link="/catalog/apex" image={apex} logo={apex_logo} title="Apex Legends" product="1" price="119" />
      <GamesCatalogTitle link="/catalog/wot" image={wot} logo={wot_logo} title="Wot" product="2" price="190" />
      <GamesCatalogTitle link="/catalog/warthunder" image={warthunder} logo={warthunder_logo} title="WarThunder" product="1" price="299" />
      <GamesCatalogTitle link="/catalog/other" image={tgprems} logo={other_logo} title="Прочее" product="1" price="1400" />
        {/*<GamesCatalogTitle link="/catalog/eft" image={tarkov} logo={tarkov_logo} title="Escape From Tarkov" product="8" price="190" />
        <GamesCatalogTitle link="/catalog/valorant" image={valorant} logo={valorant_logo} title="Valorant" product="2" price="190" />
        <GamesCatalogTitle link="/catalog/fortnite" image={fortnite} logo={fortnite_logo} title="Fortnite" product="1" price="290" />
        <GamesCatalogTitle link="/catalog/rust" image={rust} logo={rust_logo} title="Rust" product="2" price="90" />
        <GamesCatalogTitle link="/catalog/warzone" image={warzone} logo={warzone_logo} title="Warzone" product="2" price="190" />
        <GamesCatalogTitle link="/catalog/warface" image={warface} logo={warface_logo} title="Warface" product="2" price="79" />
        <GamesCatalogTitle link="/catalog/dayz" image={dayz} logo={dayz_logo} title="Dayz" product="2" price="290" />
        <GamesCatalogTitle link="/catalog/scum" image={scum} logo={scum_logo} title="Scum" product="1" price="290" />
        <GamesCatalogTitle link="/catalog/pubg" image={pubg} logo={pubg_logo} title="PUBG" product="4" price="190" />
        <GamesCatalogTitle link="/catalog/bloodhunt" image={bloodhunt} logo={bloodhunt_logo} title="Bloodhunt" product="2" price="190" />
  <GamesCatalogTitle link="/catalog/rainbow" image={rainbow} logo={rainbow_logo} title="Rainbow Six" product="1" price="99" />*/}
      </div>
    </div>
  );
}

export default GamesCatalog;
