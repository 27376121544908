import React, { useState } from "react";
import "./Functional.css";

import { Link, NavLink } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { CSSTransition as CSSTransition2 } from "react-transition-group";
import arrow_gray from "../../images/select__arrow_gray.svg";
import apex from "../../images/apex.svg";
import Undetected from "../Status/Undetected/Undetected";
import Risk from "../Status/Risk/Risk";
import Update from "../Status/Update/Update";
import esp from "../../images/esp.svg";
import aim from "../../images/aim.svg";
import loot from "../../images/loot.svg";
import misc from "../../images/misc.svg";
import apex_media from "../../images/product/apex3.jpg";
import os from "../../images/supported_os.svg";
import processors from "../../images/supported_processors.svg";
import rus from "../../images/flag__russia.png";
import usa from "../../images/flag__usa.png";
import useStatusStore from "../../store";
import Status from "../Status";
import Navigation from "./Navigation";
import Times2 from "./Times2";
import Description from "./Description";
import System from "./System";

import { useTranslation } from "react-i18next";
import "../../i18next";

function CRBApexRage() {
  const [show, setShow] = useState("day");
  const status = useStatusStore((store) => store.status);

  const handleDayClick = () => {
    setShow("day");
  };
  const handleWeekClick = () => {
    setShow("week");
  };
  const handleMouthClick = () => {
    setShow("mouth");
  };

  const activeColor = "sub__button-active";

  const inactiveColor = "sub__button";

  const { t, i18n } = useTranslation();
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  }

  return (
    <section className="functional">
      <div className="functional__container">
        <Navigation
          link="/catalog/apex"
          title="Apex Legends"
          link2="/apex/crrapexrage"
          subtitle="CRB Apex Rage"
        />

        <div className="functional__name_wrap">
          <h2 className="functional__title">CRB Apex Rage</h2>
          <div className="functional__name_wrapper">
            <div className="functional__name_wrapper_title">
              <img className="functional__image" src={apex} alt="apex" />
              <p className="functional__text">Apex Legends</p>
            </div>
            <Status status={status.apex_rage} />
          </div>
          <p className="functional__description_title">{t("about.description")}</p>
          <Description name="CRB Apex Rage" game="Apex Legends" />
        </div>
      </div>
      {/* Info */}
      <div className="functional__info">
        <div className="functional__info_wrap">
          <h3 className="functional__info_title">{t("about.functional")}</h3>
          {/* Functionals */}
          <div className="functional__info_wrapper">
           
          <div className="functional__function_wrap">
              <div className="functional__function_wrapper">
                <img
                  className="functional__function_image"
                  src={aim}
                  alt="aim"
                />
                <h4 className="functional__function_title">AIM</h4>
              </div>
              <div className="functional__functions">
                <button className="functional_function">Ignors Knocked</button>
                <button className="functional_function">pSilent</button>
                <button className="functional_function">No Sway</button>
                <button className="functional_function">No Recoil</button>
                <button className="functional_function">Team Check</button>
                <button className="functional_function">Max Distance</button>
                <button className="functional_function">Smooth</button>
                <button className="functional_function">FOV</button>
                <button className="functional_function">Silent Grenade</button>
              </div>
            </div>

            <div className="functional__function_wrap">
              <div className="functional__function_wrapper">
                <img
                  className="functional__function_image"
                  src={esp}
                  alt="esp"
                />
                <h4 className="functional__function_title">ESP</h4>
              </div>
              <div className="functional__functions">
                 <button className="functional_function">Enabled</button>
                 <button className="functional_function">Name</button>
                 <button className="functional_function">Off NPC</button>
                 <button className="functional_function">Distance</button>
                 <button className="functional_function">Skeleton</button>
                 <button className="functional_function">Warning System</button>
                 <button className="functional_function">Barrel</button>
                 <button className="functional_function">Spectators Mod</button>
                 <button className="functional_function">Head Circle</button>
                 <button className="functional_function">Max Distance</button>
                 <button className="functional_function">Box</button>
                 <button className="functional_function">Health Bar</button>
                 <button className="functional_function">Armor Bar</button>
                 <button className="functional_function">Visibility Snapline</button>
                 <button className="functional_function">Distance</button>
                 <button className="functional_function">Max Distance</button>
                 <button className="functional_function">Scale</button>
                 <button className="functional_function">Pos X</button>
                 <button className="functional_function">Pos Y</button>
                 <button className="functional_function">Size</button>
                 <button className="functional_function">Ammo</button>
                 <button className="functional_function">Weapon</button>
                 <button className="functional_function">Scope</button>
                 <button className="functional_function">Backpack</button>
                 <button className="functional_function">Armor</button>
                 <button className="functional_function">Helmet</button>
                 <button className="functional_function">The Rest</button>
                 <button className="functional_function">Grenade</button>
                 <button className="functional_function">Max Distance</button>
                 <button className="functional_function">Draw Crosshair</button>
                 <button className="functional_function">Text Box</button>
                 <button className="functional_function">Draw FOV</button>
                 <button className="functional_function">Player Glow</button>
                 <button className="functional_function">Item Glow</button>
                 <button className="functional_function">Brightness</button>
              </div>
            </div>

            <div className="functional__function_wrap">
              <div className="functional__function_wrapper">
                <img
                  className="functional__function_image"
                  src={misc}
                  alt="misc"
                />
                <h4 className="functional__function_title">MISC</h4>
              </div>
              <div className="functional__functions">
              <button className="functional_function">Fake Duck</button>
              <button className="functional_function">Free Camera</button>
              <button className="functional_function">Third Person</button>
              <button className="functional_function">Bunny Hop </button>
              <button className="functional_function">Auto Pistol</button>
              <button className="functional_function">Charge Riffle Infinite Bullets</button>
              <button className="functional_function">Skin Changer</button>
              <button className="functional_function">Skin Type</button>
              <button className="functional_function">Skin </button>
              <button className="functional_function">Airstuck </button>
              <button className="functional_function">Airstuck Key</button>
              <button className="functional_function">Inst Grenade</button>
              </div>
            </div>
          </div>
        </div>
        {/* Media */}
        <div className="functional__media">
          <p className="functional__media_title">{t("about.media")}</p>
          <img
            className="functional__media_logo"
            src={apex_media}
            alt="arexrage"
          />
         
        </div>
      </div>
      {/* System */}
      <System win="Windows 10" win2="Windows 11" proc="Intel" proc2="AMD" />
      {/* Subscription plans */}
      <div className="sub__container">
        <p className="sub__title">{t("about.plans")}</p>
        <div className="sub__wrap_buttons">
          <button
            className={show === "day" ? activeColor : inactiveColor}
            onClick={handleDayClick}
          >
            1 day
          </button>
          <button
            className={show === "week" ? activeColor : inactiveColor}
            onClick={handleWeekClick}
          >
            7 day
          </button>
          <button
            className={show === "mouth" ? activeColor : inactiveColor}
            onClick={handleMouthClick}
          >
            30 day
          </button>
        </div>
        {/* 1 day */}
        {show === "day" && (
          <Times2
            link="https://t.me/crb_shop_bot"
            link2="https://t.me/crb_shop_bot"
            price="299"
            price2="3,21"
          />
        )}
        {/* 7 days */}
        {show === "week" && (
          <Times2
            link="https://t.me/crb_shop_bot"
            link2="https://t.me/crb_shop_bot"
            price="999"
            price2="10,74"
          />
        )}
        {/* 30 days */}
        {show === "mouth" && (
          <Times2
            link="https://t.me/crb_shop_bot"
            link2="https://t.me/crb_shop_bot"
            price="2499"
            price2="26,86"
          />
        )}
      </div>
    </section>
  );
}

export default CRBApexRage;
