import React from "react";
import "../Catalog/Catalog.css";
import "./Faq.css";
import { Link } from "react-router-dom";
// Cards
// import box from "../../images/games__logo_box.svg";

import { useTranslation } from "react-i18next";
import "../../i18next";

function FaqCard(props) {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <div className="faq__card_container">
      <div className="faq__wrap">
        <div className="faq__wrapper">
          <div className="faq__inner_container">
            {/* <img /> */}
            <div>
              <p className="faq__driver_title">{props.title}</p>
              <p className="faq__driver_subtitle">{props.subtitle}</p>
            </div>
            <a href={props.link} target="_blank" rel="noreferrer">
              <button className="faq__button">Скачать</button>
            </a>
          </div>
          {/* <div className="games__wrapper_width">
            <div className="games__wrapper_title-wrap">
              <img className="games__logo" src={props.logo} alt="apex" />
              <p className="games__logo_title">{props.title}</p>
            </div>
            <div className="games__wrapper_product">
              <h3 className="games__wrapper_title">{t("about.amount")}</h3>
              <div className="games__wrapper_product_box">
                <p className="games__wrapper_title">
                  {props.product} {t("about.products")}
                </p>
              </div>
            </div>
            <div className="games__wrapper_price">
              <p className="games__wrapper_title">{t("about.price")}</p>
              <p className="games__wrapper_subtitle">
                <span className="games__wrapper_subtitle-span">{t("about.from")}</span>{" "}
                {props.price}₽
              </p>
            </div>
            <button className="games__button">{t("about.viewCatalog")}</button>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default FaqCard;
