import React from "react";
import "./Catalog.css";
import { Link } from "react-router-dom";
// Cards
import box from "../../images/games__logo_box.svg";

import { useTranslation } from "react-i18next";
import "../../i18next";

function GamesCatalogTitle(props) {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <div className="select__games_width">
      <Link className="games__link" to={props.link}>
        <div className="games__wrap" style={{ backgroundImage: `url(${props.image})` }}>
          <div className="games__wrapper">
            {/* <img className="games__image" src={props.image} alt="apex" /> */}
            <div className="games__wrapper_width">
              <div className="games__wrapper_title-wrap">
                <img className="games__logo" src={props.logo} alt="apex" />
                <p className="games__logo_title">{props.title}</p>
              </div>
              <div className="games__wrapper_product">
                <h3 className="games__wrapper_title">{t("about.amount")}</h3>
                <div className="games__wrapper_product_box">
                  <img className="games__wrapper_logo" src={box} alt="box" />
                  <p className="games__wrapper_title">
                    {props.product} {t("about.products")}
                  </p>
                </div>
              </div>
              <div className="games__wrapper_price">
                <p className="games__wrapper_title">{t("about.price")}</p>
                <p className="games__wrapper_subtitle">
                  <span className="games__wrapper_subtitle-span">{t("about.from")}</span>{" "}
                  {props.price}₽
                </p>
              </div>
              <button className="games__button">{t("about.viewCatalog")}</button>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default GamesCatalogTitle;
