import React from "react";
import "./Functional.css";
import rus from "../../images/flag__russia.png";
import usa from "../../images/flag__usa.png";

import { useTranslation } from "react-i18next";
import "../../i18next";

function Times2(props) {

  const { t, i18n } = useTranslation();
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  }

  return (
    <div className="sub__wrap">
      <a className="sub__link" href={props.link} target="_blank">
        <div className="sub__wrapper">
          <div className="sub__region_wrap">
            <img className="sub__region_logo" src={rus} alt="rus" />
            <p className="sub__region_title">CIS region</p>
          </div>
          <div className="sub__price_wrap">
            <p className="sub__price_title">{t("about.price")}</p>
            <p className="sub__price_subtitle">
              {props.price}
              <span className="span">₽</span>
            </p>
          </div>
          <button className="sub__purchase">{t("about.buy")}</button>
        </div>
      </a>
      <a className="sub__link" href={props.link2} target="_blank">
        <div className="sub__wrapper">
          <div className="sub__region_wrap">
            <img className="sub__region_logo" src={usa} alt="rus" />
            <p className="sub__region_title">EU region</p>
          </div>
          <div className="sub__price_wrap">
            <p className="sub__price_title">{t("about.price")}</p>
            <p className="sub__price_subtitle">
              {props.price2}
              <span className="span">$</span>
            </p>
          </div>
          <button className="sub__purchase">{t("about.buy")}</button>
        </div>
      </a>
    </div>
  );
}

export default Times2;
