import React from "react";
import "./Games.css";
import apex from "../../images/games__apex.svg";
import apex_logo from "../../images/games__apex_logo.svg";
import wot from "../../images/product/products_wot.jpg";
import wot_logo from "../../images/wot_white.svg";
import warthunder from "../../images/wart.jpg";
import warthunder_logo from "../../images/warthunder.png";
import { Link } from "react-router-dom";
import GamesCatalogTitle from "../Catalog/GamesCatalogTitle";
import tgprems from "../../images/tgprem.png";
import tg_logo from "../../images/footer__telegram.svg";
import { useTranslation } from "react-i18next";
import "../../i18next";
import other_logo from "../../images/games__logo_box.svg";
export default function Games() {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <section className="games">
      <div className="games__container">
        <div className="games__wrap_title">
          <h2 className="games__title">{t("about.popularGames")}</h2>
          <Link className="games__link" to="/catalog">
            <button className="games__button_title">{t("about.viewCatalog")}</button>
          </Link>
        </div>
        <div className="games__wrap_grid">
          <GamesCatalogTitle
            link="/catalog/apex"
            image={apex}
            logo={apex_logo}
            title="Apex Legends"
            product="2"
            price="119"
          />
          <GamesCatalogTitle
            link="/catalog/wot"
            image={wot}
            logo={wot_logo}
            title="Wot"
            product="2"
            price="199"
          />

          <GamesCatalogTitle
            link="/catalog/warthunder"
            image={warthunder}
            logo={warthunder_logo}
            title="War Thunder"
            product="1"
            price="299"
          />
        <GamesCatalogTitle
          link="/catalog/other"
          image={tgprems}
          logo={other_logo}
          title="Прочее"
          product="1"
          price="1400"
          />

        </div>
      </div>
    </section>
  );
}
