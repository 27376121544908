import React from "react";
import "./Catalog.css";
import { Link } from "react-router-dom";
import arrow_gray from "../../images/select__arrow_gray.svg";

import { useTranslation } from "react-i18next";
import "../../i18next";

function CatalogGrey(props) {

  const { t, i18n } = useTranslation();
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  }

  return (
    <section className="select">
      <div
        className="select__container"
        style={{ backgroundImage: `url(${props.backgroundImage})` }}
      >
        <div className="select__navigation">
          <Link className="select__link" to="/">
            <p className="select__navigation_text">{t("about.home")}</p>
          </Link>
          <img className="select__arrow_gray" src={arrow_gray} alt="arrow" />
          <Link className="select__link" to="/catalog">
            <p className="select__navigation_text-active">{t("about.catalog")}</p>
          </Link>
        </div>
        <div className="select__wrap_grey">
          <div className="select__wrapper_center_gray">
            <div className="select__wrapper_title">
              <img className="select__logo_title" src={props.logo} alt="logo" />
              <p className="select__title">{props.title}</p>
            </div>
            <p className="select__subtitle">{props.subtitle}</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CatalogGrey;
