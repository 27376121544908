import React from "react";
import "../Catalog/Catalog.css";
import "./CheatStatus.css";
// Cards
// import box from "../../images/games__logo_box.svg";

import { useTranslation } from "react-i18next";
import "../../i18next";
import Status from "../Status";

const notAllowedStatuses = ["On Update", "Risk"];

function CheatStatusCard(props) {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  const isDisabled = notAllowedStatuses.includes(props.status);

  console.log(isDisabled);

  return (
    <div className="faq__card_container">
      <div className="cheat_status_card__wrap">
        <div className="cheat_status_card__wrapper">
          <div className="cheat_status__inner_container">
            <div style={{ display: "flex", alignItems: "center" }}>
              <img style={{ marginRight: 5, width: 35, height: 35 }} src={props.icon} alt="icon" />
              <p className="faq__driver_subtitle">{props.title}</p>
            </div>
            <div className="cheat_status_controls__wrapper">
              <Status status={props.status} />
              <a href={props.link} target="_blank" rel="noreferrer" draggable={false}>
                <button
                  className={isDisabled ? "buy__button_disabled" : "buy__button"}
                  disabled={isDisabled}
                >
                  Покупка
                </button>
              </a>
            </div>
          </div>
          {/* <div className="games__wrapper_width">
            <div className="games__wrapper_title-wrap">
              <img className="games__logo" src={props.logo} alt="apex" />
              <p className="games__logo_title">{props.title}</p>
            </div>
            <div className="games__wrapper_product">
              <h3 className="games__wrapper_title">{t("about.amount")}</h3>
              <div className="games__wrapper_product_box">
                <p className="games__wrapper_title">
                  {props.product} {t("about.products")}
                </p>
              </div>
            </div>
            <div className="games__wrapper_price">
              <p className="games__wrapper_title">{t("about.price")}</p>
              <p className="games__wrapper_subtitle">
                <span className="games__wrapper_subtitle-span">{t("about.from")}</span>{" "}
                {props.price}₽
              </p>
            </div>
            <button className="games__button">{t("about.viewCatalog")}</button>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default CheatStatusCard;
