import React from "react";
import "./Catalog.css";
import GamesCatalog from "../Catalog/GamesCatalog";
import Buttons from "../Catalog/Buttons";

import CatalogGrey from "./CatalogGrey";
import catalog_game from "../../images/catalog__game.svg";
import select_logo from "../../images/select__logo.svg";

import { useTranslation } from "react-i18next";
import "../../i18next";

function Catalog() {

  const { t, i18n } = useTranslation();
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  }

  return (
    <section>
      <CatalogGrey
        backgroundImage={select_logo}
        logo={catalog_game}
        title={t("about.catalog")}
        subtitle={t("about.catalogGamesDesc")}
      />
      <Buttons />
      <GamesCatalog />
    </section>
  );
}

export default Catalog;
