import React from "react";
import CheatStatusCard from "./CheatStatusCard";
import useStatusStore from "../../store";

import { useTranslation } from "react-i18next";
import "../../i18next";
import "./CheatStatus.css";
import catalog_game from "../../images/catalog__game.svg";
import select_logo from "../../images/select__logo.svg";
import apex_svg from "../../images/apex.svg";
import wot_svg from "../../images/wot_white.svg";
import thunder_svg from "../../images/warthunder.png";
export default function CheatStatus() {
  const status = useStatusStore((store) => store.status);
  const { t, i18n } = useTranslation();
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <section className="cheat_status">
      <div
        className="select__container"
        style={{
          backgroundImage: `url(${select_logo})`,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 250,
          marginBottom: 30,
          borderBottom: "1px solid #2D303F",
        }}
      >
        <p className="select__title">Cheat Status</p>
        {/* <div className="select__wrap_grey">
          <div className="select__wrapper_center_gray">
            <div className="select__wrapper_title"></div>
          </div>
        </div> */}
      </div>
      <div className="cheat_status__container">
        <div className="cheat_status__wrap_grid">
          <CheatStatusCard
            title="CRB GLOW"
            link="/apex/crbglow"
            status={status.apex_glow}
            icon={apex_svg}
          />
          <CheatStatusCard
            title="CRB Apex Rage"
            link="/apex/crrapexrage"
            status={status.apex_rage}
            icon={apex_svg}
          />
          <CheatStatusCard
            title="Wot Blitz"
            link="/wot/steam"
            status={status.wot_blitz}
            icon={wot_svg}
          />
          <CheatStatusCard
            title="Tanks Blitz"
            link="/wot/lesta"
            status={status.wot_blitz}
            icon={wot_svg}
          />
          <CheatStatusCard
            title="CRBWarThunder"
            link="/warthunder/CRBwar"
            status={status.war_thunder}
            icon={thunder_svg}
          />
        </div>
      </div>
    </section>
  );
}
