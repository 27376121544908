import React from "react";
import "./Product.css";
import Buttons from "../Catalog/Buttons";
import warthunder from "../../images/product/warthunder_scr.png";
import warthunder_logo from "../../images/warthunder.png";
import { Link } from "react-router-dom";
import Undetected from "../Status/Undetected/Undetected";

import CatalogTitle from "../Catalog/CatalogTitle";
import catalog_game from "../../images/warthunder.png";
import catalog__warthunder_image from "../../images/wart_catalog.svg";

import { useTranslation } from "react-i18next";
import "../../i18next";
import useStatusStore from "../../store";
import Status from "../Status";
import AllGames from "./AllGames";

function WarThunder() {
  const status = useStatusStore((store) => store.status);
  const { t, i18n } = useTranslation();
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  }

  return (
    <section className="product">
      <CatalogTitle
        backgroundImage={catalog__warthunder_image}
        link="/catalog/warthunder"
        nav="WarThunder"
        logo={catalog_game}
        title="WarThunder"
        subtitle="1"
      />
      <div className="product__container">
        <Buttons />

        <div className="product__wrap_grid">
          
        <AllGames
            link="/warthunder/CRBwar"
            image={warthunder}
            title="CRBWarThunder"
            logo={warthunder_logo}
            subtitle="WarThunder"
            status={<Status status={status.war_thunder} />}
            price="299"
          />
          
        </div>
      </div>
    </section>
  );
}

export default WarThunder;
