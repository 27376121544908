import React, { useState } from "react";
import "./Functional.css";
import { Link, NavLink } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { CSSTransition as CSSTransition2 } from "react-transition-group";
import arrow_gray from "../../images/select__arrow_gray.svg";
import wot from "../../images/wot_white.svg";
import Undetected from "../Status/Undetected/Undetected";
import Risk from "../Status/Risk/Risk";
import Update from "../Status/Update/Update";
import esp from "../../images/esp.svg";
import aim from "../../images/aim.svg";
import loot from "../../images/loot.svg";
import misc from "../../images/misc.svg";
//import wot_media from "./../images/product/products_wot.jpg";
//import tarkov_media from '../../images/tarkov_media_abs.svg';
import os from "../../images/supported_os.svg";
import processors from "../../images/supported_processors.svg";
import rus from "../../images/flag__russia.png";
import usa from "../../images/flag__usa.png";

import Navigation from "./Navigation";
import Times2 from "./Times2";
import Description from "./Description";
import System from "./System";
import useStatusStore from "../../store";
import Status from "../Status";
import { useTranslation } from "react-i18next";
import "../../i18next";

function WotSteam() {
  const [show, setShow] = useState("day");
  const status = useStatusStore((store) => store.status);
  const handleDayClick = () => {
    setShow("day");
  };
  const handleWeekClick = () => {
    setShow("week");
  };
  const handleMouthClick = () => {
    setShow("mouth");
  };

  const activeColor = "sub__button-active";

  const inactiveColor = "sub__button";

  const { t, i18n } = useTranslation();
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  }

  return (
    <section className="functional">
      <div className="functional__container">
        <Navigation
          link="/catalog/wot"
          title="Wot"
          link2="/wot/steam"
          subtitle="Wot Blitz"
        />

        <div className="functional__name_wrap">
          <h2 className="functional__title">Wot Blitz</h2>
          <div className="functional__name_wrapper">
            <div className="functional__name_wrapper_title">
              <img className="functional__image" src={wot} alt="wot" />
              <p className="functional__text">Wot</p>
            </div>
            <Status status={status.wot_blitz} />
          </div>
          <p className="functional__description_title">{t("about.description")}</p>
          <Description name="CRB Wot" game="WotBlitz" />
        </div>
      </div>
      {/* Info */}
      <div className="functional__info">
        <div className="functional__info_wrap">
          <h3 className="functional__info_title">{t("about.functional")}</h3>
          {/* Functionals */}
          <div className="functional__info_wrapper">
            
            <div className="functional__function_wrap">
              <div className="functional__function_wrapper">
                <img
                  className="functional__function_image"
                  src={esp}
                  alt="esp"
                />
                <h4 className="functional__function_title">ESP</h4>
              </div>
              <div className="functional__functions">
                <button className="functional_function">Tundra</button>
                <button className="functional_function">Tundra[NEW]</button>
                <button className="functional_function">NoShadow</button>
                <button className="functional_function">NoSky</button>
                <button className="functional_function">KillHud</button>
                <button className="functional_function">Shader ESP</button>
                <button className="functional_function">WallHack</button>
                <button className="functional_function">WH for Realistic mode</button>
                <button className="functional_function">Wireframe</button>
                <button className="functional_function">NoTrack</button>
                <button className="functional_function">Invis</button>
                <button className="functional_function">DevMode</button>
                <button className="functional_function">Chams</button>
              </div>
            </div>

            <div className="functional__function_wrap">
              <div className="functional__function_wrapper">
                <img
                  className="functional__function_image"
                  src={misc}
                  alt="misc"
                />
                <h4 className="functional__function_title">MISC</h4>
              </div>
              <div className="functional__functions">
                 <button className="functional_function">Unlock FPS</button>
                <button className="functional_function">Mobile Anim</button>
                <button className="functional_function">Dsync</button>
                <button className="functional_function">Hight Cam</button>
                <button className="functional_function">Fast Aim</button>
                <button className="functional_function">Fast Cam</button>
                <button className="functional_function">Spread [FUN]</button>
                <button className="functional_function">Inst Spread</button>
              </div>
            </div>
          </div>
        </div>

        {/* Media */}
        <div className="functional__media">
          <p className="functional__media_title">{t("about.media")}</p>

            <img className="functional__media_logo" src="https://i.imgur.com/YiD7AuX.png" alt="wot" />
            
        </div>
      </div>
      {/* System */}
      <System win="Windows 10" win2="Windows 11" proc="Intel" proc2="AMD" />
      {/* Subscription plans */}
      <div className="sub__container">
        <p className="sub__title">{t("about.plans")}</p>
        <div className="sub__wrap_buttons">
          <button
            className={show === "day" ? activeColor : inactiveColor}
            onClick={handleDayClick}
          >
            3 day
          </button>
          <button
            className={show === "week" ? activeColor : inactiveColor}
            onClick={handleWeekClick}
          >
            7 day
          </button>
          <button
            className={show === "mouth" ? activeColor : inactiveColor}
            onClick={handleMouthClick}
          >
            30 day
          </button>
        </div>
        {/* 1 day */}
        {show === "day" && (
          <Times2
            link="https://t.me/crb_shop_bot"
            link2="https://t.me/crb_shop_bot"
            price="199"
            price2="2,13"
          />
        )}
        {/* 7 days */}
        {show === "week" && (
          <Times2
            link="https://t.me/crb_shop_bot"
            link2="https://t.me/crb_shop_bot"
            price="399"
            price2="4,27"
          />
        )}
        {/* 30 days */}
        {show === "mouth" && (
          <Times2
            link="https://t.me/crb_shop_bot"
            link2="https://t.me/crb_shop_bot"
            price="899"
            price2="9,62"
          />
        )}
      </div>
    </section>
  );
}

export default WotSteam;
