import React, { useState } from "react";
import "./Functional.css";
import { Link, NavLink } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { CSSTransition as CSSTransition2 } from "react-transition-group";
import arrow_gray from "../../images/select__arrow_gray.svg";
import warthunder from "../../images/warthunder.png";
import Undetected from "../Status/Undetected/Undetected";
import Risk from "../Status/Risk/Risk";
import Update from "../Status/Update/Update";
import esp from "../../images/esp.svg";
import aim from "../../images/aim.svg";
import loot from "../../images/loot.svg";
import misc from "../../images/misc.svg";
import warthunder_media from "../../images/product/warthunder_scr.png";
import os from "../../images/supported_os.svg";
import processors from "../../images/supported_processors.svg";
import rus from "../../images/flag__russia.png";
import usa from "../../images/flag__usa.png";
import useStatusStore from "../../store";
import Status from "../Status";
import Navigation from "./Navigation";
import Times2 from "./Times2";
import Description from "./Description";
import System from "./System";

import { useTranslation } from "react-i18next";
import "../../i18next";

function CRBwarthunder() {
  const [show, setShow] = useState("day");
  const status = useStatusStore((store) => store.status);
  const handleDayClick = () => {
    setShow("day");
  };
  const handleWeekClick = () => {
    setShow("week");
  };
  const handleMouthClick = () => {
    setShow("mouth");
  };

  const activeColor = "sub__button-active";

  const inactiveColor = "sub__button";

  const { t, i18n } = useTranslation();
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  }

  return (
    <section className="functional">
      <div className="functional__container">
        <Navigation
          link="/catalog/warthunder"
          title="WarThunder"
          link2="/warthunder/CRBwar"
          subtitle="CRBWarThunder"
        />

        <div className="functional__name_wrap">
          <h2 className="functional__title">CRBWarThunder</h2>
          <div className="functional__name_wrapper">
            <div className="functional__name_wrapper_title">
              <img className="functional__image" src={warthunder} alt="warthunder" />
              <p className="functional__text">WarThunder</p>
            </div>
            <Status status={status.war_thunder} />
          </div>
          <p className="functional__description_title">{t("about.description")}</p>
          <Description name="CRBWarThunder" game="WarThunder" />
        </div>
      </div>
      {/* Info */}
      <div className="functional__info">
        <div className="functional__info_wrap">
          <h3 className="functional__info_title">{t("about.functional")}</h3>
          {/* Functionals */}
          <div className="functional__info_wrapper">
            
          <div className="functional__function_wrap">
              <div className="functional__function_wrapper">
                <img
                  className="functional__function_image"
                  src={aim}
                  alt="aim"
                />
                <h4 className="functional__function_title">AIM</h4>
              </div>
              <div className="functional__functions">
                <button className="functional_function">ENABLE</button>
                <button className="functional_function">KEY</button>
                <button className="functional_function">TYPE</button>
                <button className="functional_function">FOV</button>
                <button className="functional_function">DRAW FOV</button>
                <button className="functional_function">FOV COLOR</button>
                <button className="functional_function">SMOOTH</button>
                <button className="functional_function">PREDICTION CIRCLE</button>
                <button className="functional_function">CIRCLE COLOR</button>
                <button className="functional_function">LINE TO TARGET</button>
                <button className="functional_function">LINE COLOR</button>
              </div>
            </div>
            
            <div className="functional__function_wrap">
              <div className="functional__function_wrapper">
                <img
                  className="functional__function_image"
                  src={esp}
                  alt="esp"
                />
                <h4 className="functional__function_title">ESP</h4>
              </div>
              <div className="functional__functions">
              <button className="functional_function">ENABLE</button>
              <button className="functional_function">DRAW BOX</button>
              <button className="functional_function">ENEMY BOX COLOR</button>
              <button className="functional_function">TEAMMATES BOX COLOR</button>
              <button className="functional_function">INVULNERABLE COLOR</button>
              <button className="functional_function">SHOW BOTS</button>
              <button className="functional_function">SHOW TEAMMATES</button>
              <button className="functional_function">BOX TYPE </button>
              <button className="functional_function">UNIT INFO DISPLAY TYPE</button>
              <button className="functional_function">DISTANCE TO PLAYER</button>
              <button className="functional_function">TEXT COLOR</button>
              <button className="functional_function">BACKGROUND COLOR</button>
              <button className="functional_function">MEASUREMENT UNIT</button>
              <button className="functional_function">RELOAD BAR</button>
              <button className="functional_function">FULL BAR COLOR</button>
              <button className="functional_function">FILLING BAR COLOR</button>
              <button className="functional_function">SHOW BARRELS</button>
              <button className="functional_function">BARREL LINE COLOR</button>
              <button className="functional_function">SHOW DAMAGE MODEL</button>
              <button className="functional_function">SHOW PLANES SPEED</button>
              <button className="functional_function">SHOW BOMBS</button>
              <button className="functional_function">SHOW ROCKETS</button>
              <button className="functional_function">TEXT COLOR</button>
              <button className="functional_function">BACKGROUND COLOR</button>
              </div>
            </div>

            <div className="functional__function_wrap">
              <div className="functional__function_wrapper">
                <img
                  className="functional__function_image"
                  src={misc}
                  alt="misc"
                />
                <h4 className="functional__function_title">MISC</h4>
              </div>
              <div className="functional__functions">
              <button className="functional_function">ENABLE</button>
              <button className="functional_function">PLAYER GLOW</button>
              <button className="functional_function">PENETRATION CROSSHAIR</button>
              <button className="functional_function">DISTANCE IN SCOPE</button>
              <button className="functional_function">SHOW CUSTOM BOMBSIGHT</button>
              <button className="functional_function">AIR-TO-AIR PREDICTION</button>
              <button className="functional_function">AIR-TO-AIR INDICATOR</button>
              <button className="functional_function">CCIP FOR GUNS</button>
              <button className="functional_function">CCIP FOR MISSILES</button>
              <button className="functional_function">CCIP FOR BOMBS</button>
              <button className="functional_function">SHOW REPORTS COUNT</button>
              <button className="functional_function">AIRCRAFT ARCADE CONTROLS</button>
              <button className="functional_function">ENEMY TYPES TABLE</button>
              <button className="functional_function">ARCADE INDICATORS</button>
              </div>
            </div>
          </div>
        </div>
        {/* Media */}
        <div className="functional__media">
          <p className="functional__media_title">{t("about.media")}</p>
          <img
            className="functional__media_logo"
            src={warthunder_media}
            alt="warzone"
          />
          
        </div>
      </div>
      {/* System */}
      <System win="Windows 10" win2="Windows 11" proc="Intel" proc2="AMD" />
      {/* Subscription plans */}
      <div className="sub__container">
        <p className="sub__title">{t("about.plans")}</p>
        <div className="sub__wrap_buttons">
          <button
            className={show === "day" ? activeColor : inactiveColor}
            onClick={handleDayClick}
          >
            1 day
          </button>
          <button
            className={show === "week" ? activeColor : inactiveColor}
            onClick={handleWeekClick}
          >
            7 day
          </button>
          <button
            className={show === "mouth" ? activeColor : inactiveColor}
            onClick={handleMouthClick}
          >
            30 day
          </button>
        </div>
        {/* 1 day */}
        {show === "day" && (
          <Times2
            link="https://t.me/crb_shop_bot"
            link2="https://t.me/crb_shop_bot"
            price="299"
            price2="3,2"
          />
        )}
        {/* 7 days */}
        {show === "week" && (
          <Times2
            link="https://t.me/crb_shop_bot"
            link2="https://t.me/crb_shop_bot"
            price="999"
            price2="10,69"
          />
        )}
        {/* 30 days */}
        {show === "mouth" && (
          <Times2
            link="https://t.me/crb_shop_bot"
            link2="https://t.me/crb_shop_bot"
            price="2499"
            price2="26,74"
          />
        )}
      </div>
    </section>
  );
}

export default CRBwarthunder;
