import React from "react";
import "../Status.css";

function Risk() {
  return (
    <div className="risk">
      {/* <div className="risk__circle" /> */}
      <i className="fa fa-shield-check" style={{ marginRight: "5px" }}></i>
      <p className="risk__text">Use at own risk</p>
    </div>
  );
}

export default Risk;
