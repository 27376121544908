import React from "react";
import "./Product.css";
import Buttons from "../Catalog/Buttons";
import tgprems from "../../images/tgprem.png";
import crbapexrage from "../../images/product/apex3.jpg";
import tg_logo from "../../images/footer__telegram.svg";
import { Link } from "react-router-dom";
import Undetected from "../Status/Undetected/Undetected";
import other_logo from "../../images/games__logo_box.svg";
import CatalogTitle from "../Catalog/CatalogTitle";
import other_image from "../../images/other_bg.svg";

import { useTranslation } from "react-i18next";
import "../../i18next";
import AllOther from "./AllOther";
import Update from "../Status/Update/Update";

function Other() {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <section className="product">
      <CatalogTitle
        backgroundImage={other_image}
        link="/catalog/other"
        nav="Прочее"
        logo={other_logo}
        title="Прочее"
        subtitle="1"
      />
      <div className="product__container">
        <Buttons />

        <div className="product__wrap_grid">
          <AllOther
            link="https://t.me/crb_shop_bot"
            image={tgprems}
            title="Telegram Premium 12 months"
            logo={tg_logo}
            subtitle="Telegram"
            status="Online"
            price="1400"
          />
        </div>
      </div>
    </section>
  );
}

export default Other;
