import React from "react";
import "./Main.css";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";
import "../../i18next";

function Main() {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  }

  return (
    <main className="main">
      <div className="main__container">
        <div className="main__wrapper">
          <a
            className="main__link"
            href="https://t.me/ztreh"
            target="_blank"
          >
            <div className="main__telegram_wrapper">
              <div className="main__telegram_logo" />
              <div className="main__telegram">
                {t("about.telegram")}
              </div>
            </div>
          </a>
          <p className="main__title">
            <span className="main__title_color">{t("about.bestShop")}</span> {t("about.aboutShop")}
          </p>
          <p className="main__subtitle">
            {t("about.catalogDesc")}
          </p>
          <div className="main__button_wrapper">
            <Link className="main__link" to="/catalog">
              <button className="main__button_catalog">{t("about.catalog")}</button>
            </Link>
            {/* <button className="main__button_search">
                    <div className="main__button_logo" />
                    <input className="main__input" minLength={2} maxLength={25} placeholder="Search" />
                </button> */}
          </div>
          <div className="main__information">
            <div className="main__information_wrapper">
              <div className="main__information_width">
                <div className="main__information_logo-games" />
                <div className="main__information_wrap">
                  <p className="main__information_title">3+</p>
                  <p className="main__information_subtitle">{t("about.descGames")}</p>
                </div>
              </div>

              <div className="main__information_width">
                <div className="main__information_logo-cheats" />
                <div className="main__information_wrap">
                  <p className="main__information_title">5+</p>
                  <p className="main__information_subtitle">{t("about.descCheats")}</p>
                </div>
              </div>

              <div className="main__information_width">
                <div className="main__information_logo-developers" />
                <div className="main__information_wrap">
                  <p className="main__information_title">1+</p>
                  <p className="main__information_subtitle">{t("about.descDev")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Main;
