import React, { useState } from "react";
import "./Catalog.css";
import { Link } from "react-router-dom";
// Buttons
import eft_button from "../../images/products__tarkov_logo.svg";
import val_button from "../../images/products__valorant_logo.svg";
import wot_button from "../../images/wot_white.svg";
import warthunder_button from "../../images/warthunder.png";
import fort_button from "../../images/products__fortnite_logo.svg";
import rust_button from "../../images/products__rust_logo.svg";
import apex_button from "../../images/products__apex_logo.svg";
import spoof_button from "../../images/r6s_grey.svg";
import warzone_button from "../../images/warzone_grey.svg";
import warface_button from "../../images/warface_grey.svg";
import dayz_button from "../../images/products__dayz.svg";
import scum_button from "../../images/products__scum.svg";
import pubg_button from "../../images/pubg_logo_gray.svg";
import bloodhunt__button from "../../images/products__bloodhunt_logo.svg";
import bf2042_button  from "../../images/Battlefield_2042_logo.png";
import other_logo from "../../images/games__logo_box.svg";
function Buttons() {
  return (
    <section className="select">
      <div className="select__wrapper_width">
        <div className="select__wrapper">
          <div className="select__type">
           {/*
            <Link className="select__link" to="/catalog/eft">
              <button className="select__button">
                <img className="select__logo" src={eft_button} alt="eft" />
                <p className="select__text">EFT</p>
              </button>
                </Link>

                <Link className="select__link" to="/catalog/valorant">
              <button className="select__button">
                <img className="select__logo" src={val_button} alt="val" />
                <p className="select__text">Valorant</p>
              </button>
            </Link>

            <Link className="select__link" to="/catalog/fortnite">
              <button className="select__button">
                <img className="select__logo" src={fort_button} alt="fort" />
                <p className="select__text">Fortnite</p>
              </button>
            </Link>

            <Link className="select__link" to="/catalog/warzone">
              <button className="select__button">
                <img className="select__logo" src={warzone_button} alt="dayz" />
                <p className="select__text">Warzone</p>
              </button>
            </Link>

            <Link className="select__link" to="/catalog/warface">
              <button className="select__button">
                <img className="select__logo" src={warface_button} alt="dayz" />
                <p className="select__text">Warface</p>
              </button>
            </Link>

            <Link className="select__link" to="/catalog/dayz">
              <button className="select__button">
                <img className="select__logo" src={dayz_button} alt="dayz" />
                <p className="select__text">Dayz</p>
              </button>
            </Link>

            <Link className="select__link" to="/catalog/scum">
              <button className="select__button">
                <img className="select__logo" src={scum_button} alt="scum" />
                <p className="select__text">Scum</p>
              </button>
            </Link>

            <Link className="select__link" to="/catalog/pubg">
              <button className="select__button">
                <img className="select__logo" src={pubg_button} alt="spoof" />
                <p className="select__text">Pubg</p>
              </button>
            </Link>

            <Link className="select__link" to="/catalog/bloodhunt">
              <button className="select__button">
                <img
                  className="select__logo"
                  src={bloodhunt__button}
                  alt="pubg"
                />
                <p className="select__text">Bloodhunt</p>
              </button>
            </Link>

            <Link className="select__link" to="/catalog/rainbow">
              <button className="select__button">
                <img className="select__logo" src={spoof_button} alt="pubg" />
                <p className="select__text">Rainbow</p>
              </button>
            </Link>
*/}
            <Link className="select__link" to="/catalog/rust">
              <button className="select__button">
                <img className="select__logo" src={rust_button} alt="rust" />
                <p className="select__text">Rust</p>
              </button>
            </Link>

            
            <Link className="select__link" to="/catalog/apex">
              <button className="select__button">
                <img className="select__logo" src={apex_button} alt="apex" />
                <p className="select__text">Apex</p>
              </button>
            </Link>

            <Link className="select__link" to="/catalog/wot">
              <button className="select__button">
                <img className="select__logo" src={wot_button} alt="wot" />
                <p className="select__text">Wot</p>
              </button>
            </Link>

            <Link className="select__link" to="/catalog/warthunder">
              <button className="select__button">
                <img className="select__logo" src={warthunder_button} alt="warthunder" />
                <p className="select__text">WarThunder</p>
              </button>
            </Link>

            <Link className="select__link" to="/catalog/bf2042">
              <button className="select__button">
                <img className="select__logo" src={bf2042_button} alt="bf2042" />
                <p className="select__text">Battlefield 2042</p>
              </button>
            </Link>
            <Link className="select__link" to="/catalog/other">
              <button className="select__button">
                <img className="select__logo" src={other_logo} alt="others" />
                <p className="select__text">Прочее</p>
              </button>
            </Link>
            

            
          </div>
        </div>
      </div>
    </section>
  );
}

export default Buttons;
