import React from "react";
import "./Functional.css";
import os from "../../images/supported_os.svg";
import processors from "../../images/supported_processors.svg";

import { useTranslation } from "react-i18next";
import "../../i18next";

function CamelEFT(props) {

  const { t, i18n } = useTranslation();
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  }

  return (
      <div className="functional__system">
        <h5 className="functional__system_title">{t("about.system")}</h5>
        <div className="functional__system_wrap-box">
          <div className="functional__system_wrap">
            <img className="functional__system_logo" src={os} alt="OS" />
            <div className="functional__system_wrapper">
              <p className="functional__system_text">{t("about.oc")}</p>
              <div className="functional__system_wrapper_box">
                <p className="functional__system_subtitle">{props.win}</p>
                <p className="functional__system_subtitle">{props.win2}</p>
              </div>
            </div>
          </div>
          <div className="functional__system_wrap">
            <img
              className="functional__system_logo"
              src={processors}
              alt="processors"
            />
            <div className="functional__system_wrapper">
              <p className="functional__system_text">
                {t("about.proc")}
              </p>
              <div className="functional__system_wrapper_box">
                <p className="functional__system_subtitle">{props.proc}</p>
                <p className="functional__system_subtitle">{props.proc2}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

export default CamelEFT;
