import React from "react";
import "../Status.css";

function Undetected() {
  return (
    <div className="undetected">
      {/* <div className="undetected__circle" /> */}
      <i className="fa fa-shield-check" style={{ marginRight: "5px" }}></i>
      <p className="undetected__text">Undetected</p>
    </div>
  );
}

export default Undetected;
